.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.body {
  max-height: 85vh;
  overflow: auto;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formdiv {
  font-family: 'Poppins', sans-serif;
  border: 2px solid #d3d3d3;
  border-radius: .5em;
  padding: 1em;
  text-align: left;
  width: 400px;
  background-color: rgba(176, 202, 148, 0.958);
}

.login-errors {
  color: red;
}

.header {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 48px;
  text-align: center;
}

.navbar {
  background-color: #b0ca94;
  text-transform: uppercase;
}

.bg-image {
  background-image: url("../images/succulent.jpeg");
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.organisms {
  display: flex;
}

.card-button {
  float: right;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
